type FieldDefinition = {
    name: string;
    type: "string" | "number" | "bool";
    sortOrder?: number;
    sortDirection?: "asc" | "desc";
}

type DataSourceDefinition = {
    id: string;
    viewName: string;
    maxRecords: number;
    whereClause: string;
    loadRecents: boolean;
    distinctRows: boolean;
    dynamicLoading?: boolean;
    fields: FieldDefinition[];
}

export const common_dsDocumentTypesLkp: DataSourceDefinition = {
    id: 'common_dsDocumentTypeLkp',
    viewName: 'aviw_Assets_DocumentTypesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string", sortOrder: 1, sortDirection: "asc" },
        { name: "DocumentGroup", type: "string"}
    ]
}

export const common_dsReleaseCodesLkp: DataSourceDefinition = {
    id: 'common_dsReleaseCodeLkp',
    viewName: 'aviw_Arena_ReleaseCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string", sortOrder: 2, sortDirection: "asc" },
        { name: "Title", type: "string" },
        { name: "SortOrder", type: "string", sortOrder: 1, sortDirection: "asc"}
    ]
}

export const common_dsPersonsWithAccess: DataSourceDefinition = {
    id: 'common_dsPersonsWithAccess',
    viewName: 'aviw_Arena_PersonsWithAccess',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        {name: "ID", type: "number"},
        {name: "Name", type: "string"},
        {name: "Document_ID", type: "string"},
        {name: "MobileNo", type: "string"},
        {name: "Email", type: "string"},
        {name: "Represents", type: "string"},
        {name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc"}
    ]
}

export const common_dsWBSLkp: DataSourceDefinition = {
    id: 'common_dsWBSLkp',
    viewName: 'aviw_Complete_WBSLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "Parent", type: "string" },
        {name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const common_dsReviewClassLkp: DataSourceDefinition = {
    id: 'common_dsReviewClassLkp',
    viewName: 'aviw_Arena_ReviewClasses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }
    ]
}

export const common_dsClassificationCode: DataSourceDefinition = {
    id: 'common_dsClassificationCodes',
    viewName: 'aviw_Assets_ClassificationCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc" }
    ]
}

export const common_dsTopics: DataSourceDefinition = {
    id: 'common_dsTopics',
    viewName: 'aviw_Arena_TopicsLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "NameAndDescription", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const common_dsProjectCodes: DataSourceDefinition = {
    id: 'common_dsProjectCodes',
    viewName: 'aviw_Assets_ProjectCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "OrgUnit", type: "string" }]
}

export const common_dsDisciplines: DataSourceDefinition = {
    id: 'common_dsDisciplines',
    viewName: 'aviw_Assets_DisciplinesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" }]
}

export const common_dsProjectPhases: DataSourceDefinition = {
    id: 'common_dsProjectPhases',
    viewName: 'atbv_Scope_ProjectPhases',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const common_dsMainAreas: DataSourceDefinition = {
    id: 'common_dsMainAreas',
    viewName: 'aviw_Assets_MainAreasLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const common_dsPersonsLkp: DataSourceDefinition = {
    id: 'common_dsPersonsLkp',
    viewName: 'sviw_System_PersonsLookupWithRoles',
    maxRecords: 25,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    dynamicLoading: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "MobileNo", type: "string" },
        {name: "Email", type: "string" },
        {name: "Represents", type: "string" }]
}

export const common_dsOrgUnitsLkp: DataSourceDefinition = {
    id: 'common_dsOrgUnitsLkp',
    viewName: 'sviw_System_OrgUnits',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "NamePath", type: "string" },
        {name: "IdPath", type: "string" },
        {name: "Parent_ID", type: "number"},
        {name: "UnitType", type: "string"},
        {name: "HasNodes", type: "bool"},
        {name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc"}] //field OrgUnit to be used?
}

export const common_dsSubProjects: DataSourceDefinition = {
    id: 'common_dsSubProjects',
    viewName: 'aviw_Scope_SubProjects',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" }]
}

export const common_dsMetaLookup: DataSourceDefinition = {
    id: 'common_dsMetaLookup',
    viewName: 'aviw_Assets_MetaObjectsWithHierarchi',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "TypeAndName", type:"string"},
        {name: "Description", type:"string"}]
}

export const common_dsMetaFields: DataSourceDefinition = {
    id: 'common_dsMetaFields',
    viewName: 'aviw_Assets_Setup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Meta1", type: "string"},
        {name: "Meta2", type: "string" },
        {name: "Meta3", type: "string" },
        {name: "Meta4", type: "string" },
        {name: "Meta5", type: "string" },
        {name: "Meta6", type: "string" },
        {name: "Meta7", type: "string" },
        {name: "Meta8", type: "string" },
        {name: "Meta9", type: "string" },
        {name: "Meta10", type: "string" },
        {name: "Meta11", type: "string" },
        {name: "Meta12", type: "string" },
        {name: "Meta13", type: "string" },
        {name: "Meta14", type: "string" },
        {name: "Meta15", type: "string" }]
}

export const common_dsRetentionCodes: DataSourceDefinition = {
    id: 'common_dsRetentionCodes',
    viewName: 'atbv_Arena_RetentionCodes',
    maxRecords: -1,
    whereClause: "Closed IS NULL",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Code", type: "string", sortOrder: 1, sortDirection: "asc"},
        {name: "Description", type: "string" }]
}

export const common_dsVoidReasons: DataSourceDefinition = {
    id: 'common_dsVoidReasons',
    viewName: 'aviw_Arena_VoidReasons',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" },
        {name: "VoidReason_ID", type: "number"}]
}

export const common_dsExtProcesses: DataSourceDefinition = {
    id: 'common_dsExtProcesses',
    viewName: 'atbv_Arena_ExternalProcesses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const common_dsExtProStatuses: DataSourceDefinition = {
    id: 'common_dsExtProStatuses',
    viewName: 'atbv_Arena_ExternalProcessesStatuses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const common_dsDocumentPackages: DataSourceDefinition = {
    id: 'common_dsDocumentPackages',
    viewName: 'aviw_Arena_DocumentPackagesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc" },
        {name: "ID", type: "number" }]
}

export const common_dsDistReqTypes: DataSourceDefinition = {
    id: 'common_dsDistReqTypes',
    viewName: 'aviw_Arena_DistributionRequirementTypesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const common_dsCompanies: DataSourceDefinition = {
    id: 'common_dsCompanies',
    viewName: 'sviw_System_Companies',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "ID", type: "number" },
        {name: "CompanyNo", type: "string" }]
}

export const common_dsPlannedChangeLkp: DataSourceDefinition = {
    id: 'common_dsPlannedChangeLkp',
    viewName: 'aviw_Arena_DocumentsPlannedChangeStatuses',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Description", type: "string" }]
}

export const common_dsCodeTypes: DataSourceDefinition = {
    id: 'common_dsCodeTypes',
    viewName: 'atbv_Arena_CodeTypes',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Sequence", type: "number", sortOrder: 1, sortDirection: "asc"}],
    
}

export const common_dsCodesLookup: DataSourceDefinition = {
    id: 'common_dsCodesLookup',
    viewName: 'aviw_Arena_CodesLookup2',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Seq", type: "string" },
        {name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const common_dsOriginatorCodesLkp: DataSourceDefinition = {
    id: 'common_dsOriginatorCodesLkp',
    viewName: 'aviw_Arena_OriginatorCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "OrgUnit", type: "string" },
        {name: "ParentOrgUnit", type: "string" },
        {name: "NameAndTitle", type: "string" }]
    
}
